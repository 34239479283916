import { render, staticRenderFns } from "./Indexv2.vue?vue&type=template&id=0e0a9206&scoped=true"
import script from "./Indexv2.vue?vue&type=script&lang=js"
export * from "./Indexv2.vue?vue&type=script&lang=js"
import style0 from "./Indexv2.vue?vue&type=style&index=0&id=0e0a9206&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e0a9206",
  null
  
)

export default component.exports